import React from "react";
import facebookIcon from "../../assets/facebook-icon.png";
import instagramIcon from "../../assets/instagram-icon.png"
import './Footer.css';

const Footer = () => {

    return (
        <footer id="footer-container">
            <div className="social-media-wrapper">
                <div className="social-media-inner-wrapper">
                <a className="facebook-icon" href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer">
                    <img src={facebookIcon} alt="facebook" />
                </a>
                <a href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer">
                    <img src={instagramIcon} alt="instagram" />
                </a>
                </div>
            </div>
            <p className="copyright">Copyright © 2022 KLASTER. Sva prava zadržana.</p>
        </footer>
    );
}

export default Footer;
