import React from "react";
import { useContext } from "react";
import { useState } from "react";
import { useRef } from "react";
import { Link } from "react-router-dom";
import { appCtx } from "../../context";
import "./Admin.css";

function AdminLoginPage() {
  const passwordRef = useRef();
  const { isLoggedIn, setIsLoggedIn } = useContext(appCtx);
  const [wrongPass, setWrongPass] = useState(false);

  const checkPassword = () => {
    const data = {
      sifra: passwordRef.current.value,
    };
    fetch(
      "https://eu-west-1.aws.data.mongodb-api.com/app/website-gyppv/endpoint/proveraAdminSifre",
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data }),
      }
    ).then((response) => {
      if (response.ok) {
        response.json().then((res) => {
          if (res.length > 0) {
            setIsLoggedIn(true);
            sessionStorage.setItem("otk-srem", "dhfubkaiusbdh");
          } else {
            setWrongPass(true);
          }
        });
      } else {
        alert(`Morate da probate ponovo`);
      }
    });
  };

  return (
    <div className="admin-container">
      {isLoggedIn ? (
        <div className="admin-publish-buttons">
          <Link to={"/admin-publish-vesti"}>Vesti</Link>
          <Link to={"/admin-publish-press"}>Clipping</Link>
          <Link to={"/admin-publish-projekat"}>Projekti</Link>
        </div>
      ) : (
        <>
          <h1>Unesite Vašu Lozinku</h1>
          <div className="regular-input">
            <input type="password" name="pass" id="login" ref={passwordRef} />
          </div>
          <div className="error-message-wrapper">
            {wrongPass && "POGREŠNA LOZINKA"}
          </div>
          <button onClick={checkPassword}>Potvrdi</button>
        </>
      )}
    </div>
  );
}

export default AdminLoginPage;
