import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { appCtx } from "../../context";
import Loader from "../Loader/Loader";
import "./Vesti.css";

const Vesti = () => {
  const { fetchedAllData } = useContext(appCtx);

  const mapVesti = () => {
    return fetchedAllData.vestiData.map((singleVest) => {
      return (
        <div className="vest-card-container" key={singleVest._id}>
          <div className="vest-img-wrapper">
            <img src={singleVest.slika} alt="slika" />
          </div>
          <div className="vest-not-img-wrapper">
            <div>
              <h2>{singleVest.naslov}</h2>
              <p className="vest-datum">{singleVest.datum}</p>
            </div>
            <p>{singleVest.kratkiOpis}</p>
            <Link to={`/vest/${singleVest._id}`}>
              <p className="btn">Saznaj više</p>
            </Link>
          </div>
        </div>
      );
    });
  };
  return (
    
    <main id="vesti-container">
      <div className="header-footer-shadow"></div>
      <div className="vesti-inner-wrapper">
        <h1>Vesti</h1>
        {fetchedAllData ? mapVesti() : <Loader />}
      </div>

      <div className="header-footer-shadow"></div>
    </main>
  );
};

export default Vesti;
