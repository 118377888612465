import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { appCtx } from "../../context";

import logoImage from "../../assets/otk-logo-natpis.svg";
import './Header.css';

const Header = () => {
    const { isLoggedIn, setIsLoggedIn } = useContext(appCtx);
    const [isMobileOpen, setIsMobileOpen] = useState(false)

    const checkIsMobileOpen = () => {
        setIsMobileOpen(!isMobileOpen)
    }

    const logOutAdmin = () => {
        sessionStorage.removeItem("otk-srem");
        setIsLoggedIn(false)
    }
    return (
        <header id="header-container">
            <div className="header-wrapper">
                <Link className="logo-wrapper" to={"/"} onClick={() => setIsMobileOpen(false)}>
                    <img src={logoImage} alt="logo" />
                </Link>

                <div className={`header-links-wrapper ${isMobileOpen ? "show-mobile-menu" : ""}`}>
                    {!isLoggedIn &&
                        <>
                            <Link to={"/o-nama"} onClick={() => setIsMobileOpen(false)}>
                                <p>O nama</p>
                            </Link>
                            <Link to={"/projekti"} onClick={() => setIsMobileOpen(false)}>
                                <p>Projekti</p>
                            </Link>
                            <Link to={"/vesti"} onClick={() => setIsMobileOpen(false)}>
                                <p>Vesti</p>
                            </Link>
                            <Link to={"/press-clipping"} onClick={() => setIsMobileOpen(false)}>
                                <p>Press clipping</p>
                            </Link>
                            <Link to={"/kontakt"} onClick={() => setIsMobileOpen(false)}>
                                <p>Kontakt</p>
                            </Link>
                        </>
                    }
                    {isLoggedIn &&
                        <>
                            <div className="admin-horizontal-line">
                            </div>
                            <Link to={"/admin-publish-vesti"} onClick={() => setIsMobileOpen(false)}>
                                <p>Objavi vest</p>
                            </Link>
                            <Link to={"/admin-publish-press"} onClick={() => setIsMobileOpen(false)}>
                                <p>Objavi Clipping</p>
                            </Link>
                            <Link to={"/admin-publish-projekat"} onClick={() => setIsMobileOpen(false)}>
                                <p>Objavi projekat</p>
                            </Link>

                            <Link to={"/"} onClick={() => { setIsMobileOpen(false); logOutAdmin() }}>
                                <p>Izloguj me</p>
                            </Link>                        </>
                    }
                </div>

                <div className="hamburger-menu-btn" onClick={checkIsMobileOpen}>
                    <div className={`hamburger-menu-btn-line ${isMobileOpen ? "rotate-down" : ""}`}></div>
                    <div className={`hamburger-menu-btn-line ${isMobileOpen ? "hide-it" : ""}`}></div>
                    <div className={`hamburger-menu-btn-line ${isMobileOpen ? "rotate-up" : ""}`}></div>
                </div>
            </div>
        </header>
    );
}

export default Header;
