import React, { useState, useMemo } from "react";
import {
  EditorState,
  ContentState,
  convertFromHTML,
  convertToRaw,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./Admin.css";

const RichTextEditorFunc = ({ body, setBody }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const htmlData = useMemo(
    () => draftToHtml(convertToRaw(editorState.getCurrentContent())),
    [editorState]
  );
  const handleChange = (data) => {
    setEditorState(data);
    setBody({ ...body, richTekst: htmlData });
  };

  const toolbarOptions = {
    options: ["inline", "fontSize", "list", "blockType", "textAlign"],
    inline: {
      options: ["bold", "italic", "underline", "strikethrough"],
    },
    blockType: {
      inDropdown: false,
      options: ["Normal", "H1", "H2", "H3", "H4", "H5", "H6", "Code"],
    },
    // pogledati kako jos malo stil da se sredi
    fontSize: {
      options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
    },
    textAlign: {
      inDropdown: false,
      options: ["left", "center", "right"],
    },
  };

  return (
    <>
      <div className="wrap">
        <Editor
          editorState={editorState}
          onEditorStateChange={handleChange}
          wrapperClassName="editor-wrapper"
          editorClassName="message-editor"
          toolbarClassName="message-toolbar"
          toolbar={toolbarOptions}
        />
      </div>
    </>
  );
};

export default RichTextEditorFunc;
