import React from "react";
import { Routes, Route, BrowserRouter, HashRouter } from "react-router-dom";
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import Onama from "./Pages/O-nama/Onama";
import Projekti from "./Pages/Projekti/Projekti";
import Vesti from "./Pages/Vesti/Vesti";
import PressClipping from "./Pages/PressClipping/PressClipping";
import Kontakt from "./Pages/Kontakt/Kontakt";
import Home from "./Pages/Home/Home";
import SingleVest from "./Pages/SingleVest/SingleVest";
import SingleProjekat from "./Pages/SingleProjekat/SingleProjekat";
import { AppProvider } from "./context";

import "./App.css";
import AdminLoginPage from "./Pages/Admin/AdminLoginPage";
import AdminPublishProjekat from "./Pages/Admin/AdminPublishProjekat";
import AdminPublishVesti from "./Pages/Admin/AdminPublishVesti";
import AdminPublishPressClipping from "./Pages/Admin/AdminPublishPressClipping";
import { useState } from "react";
import { useEffect } from "react";
import SingleClipping from "./Pages/SingleClipping/SingleClipping";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [fetchedAllData, setFetchedAllData] = useState(null);

  useEffect(() => {
    const sesija = sessionStorage.getItem("otk-srem");
    if (sesija === "dhfubkaiusbdh") {
      setIsLoggedIn(true);
    }

    fetch(
      "https://eu-west-1.aws.data.mongodb-api.com/app/website-gyppv/endpoint/allData",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((res) => {
        setFetchedAllData(res);
      });
  }, []);

  return (
    <>
      <AppProvider value={{ isLoggedIn, setIsLoggedIn, fetchedAllData }}>
        <HashRouter>
          <Header />
          <Routes>
            <Route path="/" element={<Home />} exact />
            <Route path="/o-nama" element={<Onama />} exact />
            <Route path="/projekti" element={<Projekti />} exact />
            <Route
              path="/projekat/:projekatId"
              element={<SingleProjekat />}
              exact
            />
            <Route path="/vesti" element={<Vesti />} exact />
            <Route path="/vest/:vestId" element={<SingleVest />} exact />
            <Route path="/press-clipping" element={<PressClipping />} exact />
            <Route
              path="/press-clipping/:clippingId"
              element={<SingleClipping />}
              exact
            />

            <Route path="/kontakt" element={<Kontakt />} exact />
            <Route path="/admin-login" element={<AdminLoginPage />} exact />
            <Route
              path="/admin-publish-projekat"
              element={<AdminPublishProjekat />}
              exact
            />
            <Route
              path="/admin-publish-vesti"
              element={<AdminPublishVesti />}
              exact
            />
            <Route
              path="/admin-publish-press"
              element={<AdminPublishPressClipping />}
              exact
            />
          </Routes>
          <Footer />
        </HashRouter>
      </AppProvider>
    </>
  );
}

export default App;
