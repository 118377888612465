import React from "react";
import "./Admin.css";
import S3 from "react-aws-s3";
import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { appCtx } from "../../context";
import RichTextEditorFunc from "./RichTextEditorFunc";
import moment from "moment";
import { BsCheckLg } from 'react-icons/bs';

function AdminPublishVesti() {
  const { isLoggedIn } = useContext(appCtx);
  const navigate = useNavigate();
  const [photoToUpload, setPhotoToUpload] = useState(null);
  const [isSentSuccessfully, setIsSentSuccessfully] = useState(false)
  const [body, setBody] = useState({
    naslov: "",
    kratkiOpis: "",
    slika: "",
    datum: "",
    richTekst: "",
  });
  const [isValidationFailed, setIsValidationFailed] = useState(false)

  useEffect(() => {
    !isLoggedIn ? navigate("/") : console.log()
  }, [isLoggedIn])

  const uploadImageOnAmazon = () => {
    const config = {
      bucketName: "hocu-da-radim",
      region: "eu-west-1",
      accessKeyId: "AKIAVNPTROJ64MG35EX2",
      secretAccessKey: "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",
    };
    const ReactS3Client = new S3(config);

    ReactS3Client.uploadFile(photoToUpload)
      .then((res) => {
        const lokacija = res.location;
        sendVest(lokacija + "");
      })
      .catch((err) => console.error(err));
  };

  const checkValidation = () => {
    if (body.naslov && body.kratkiOpis && body.datum && body.richTekst) {
      setIsValidationFailed(false)
      uploadImageOnAmazon()
    } else {
      setIsValidationFailed(true)
    }
  }

  const sendVest = (photoURL) => {
    const data = {
      ...body,
      slika: photoURL
    }

    fetch('https://eu-west-1.aws.data.mongodb-api.com/app/website-gyppv/endpoint/publishVest', {
      method: "POST",
      body: JSON.stringify({ data }),
      headers: {
        "Content-Type": "application/json",
      }
    }).then((response) => {
      if (response.ok) {
        setIsSentSuccessfully(true)
      } else {
        alert(`Došlo je do greške, pokušaj ponovo`)
      }
    })
  };


  return (
    <div className="admin-container">
      <h1>Objavi Vest</h1>
      <div className="regular-input">
        <input
          type="text"
          name="text"
          id="text"
          placeholder="NASLOV*"
          onChange={(event) => {
            setBody({ ...body, naslov: event.target.value });
          }}
        />
        {isValidationFailed && !body.naslov &&
          <p className="validation-msg">Obavezno polje</p>
        }
      </div>
      <div className="regular-input">
        <textarea
          rows="10"
          onChange={(event) => {
            setBody({ ...body, kratkiOpis: event.target.value });
          }}
          placeholder="KRATKI OPIS*"
        ></textarea>
        {isValidationFailed && !body.kratkiOpis &&
          <p className="validation-msg">Obavezno polje</p>
        }

      </div>
      <div className="regular-input">
        <input
          type="date"
          name="date"
          id="date"
          onChange={(e) => {
            const datte = moment(e.target.value).format("DD/MM/YYYY");
            setBody({ ...body, datum: datte });
          }}
        />
        {isValidationFailed && !body.datum &&
          <p className="validation-msg">Obavezno polje</p>
        }

      </div>

      <label htmlFor="avatar" className="photo-input">
        {!photoToUpload &&
          <span>Postavite fotografiju</span>
        }
        {photoToUpload &&
          <span>Promeni fotografiju</span>
        }
        <input
          type="file"
          id="avatar"
          name="avatar"
          accept="image/png, image/jpg, image/jpeg"
          onChange={(event) => {
            setPhotoToUpload(event.target.files[0]);
          }}
        />
      </label>

      {photoToUpload &&
        <img className="upload-photo-preview" src={URL.createObjectURL(photoToUpload)} alt="fotografija" />
      }

      <RichTextEditorFunc body={body} setBody={setBody} />
      {isValidationFailed && !body.richTekst &&
        <p className="validation-msg">Obavezno polje</p>
      }

      <button onClick={checkValidation}>OBJAVI</button>
      {isSentSuccessfully &&
        <div className="sent-successufully-modal">
          <h2>Uspešno ste objavili vest</h2>
          <BsCheckLg size={42} color={"#15ff00"} className="checkmark" />
          {setTimeout(function () { window.location.href = "http://omladinskiklaster.rs/" }, 5000)}
        </div>}
    </div>
  );
}

export default AdminPublishVesti;
