import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { appCtx } from "../../context";
import Loader from "../Loader/Loader";
import "./SingleProjekat.css";

const SingleProjekat = () => {
  const { fetchedAllData } = useContext(appCtx);
  const { projekatId } = useParams();
  const [singleProjekat, setSingleProjekat] = useState(null);

  useEffect(() => {
    let singleProjekatData = null;
    for (let i = 0; i < fetchedAllData?.projektiData.length; i++) {
      if (fetchedAllData.projektiData[i]._id == projekatId) {
        singleProjekatData = fetchedAllData.projektiData[i];
        i = fetchedAllData.projektiData.length;
      }
    }
    setSingleProjekat(singleProjekatData);
  }, [fetchedAllData, projekatId]);

  return (
    <>
      {singleProjekat && (
        <>
          <main id="single-projekat-container">
            <div className="header-footer-shadow"></div>
            <h1>{singleProjekat.naslov}</h1>
            <p className="kratki-opis">
              <i>{singleProjekat.kratkiOpis}</i>
            </p>
            <img src={singleProjekat.slika} alt="slika" />
            <div
              className="rich-text-wrapper"
              dangerouslySetInnerHTML={{ __html: singleProjekat.richTekst }}
            />
          </main>
          <div className="header-footer-shadow"></div>
        </>
      )}
      {!singleProjekat && <Loader />}
    </>
  );
};

export default SingleProjekat;
