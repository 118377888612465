import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { appCtx } from "../../context";
import Loader from "../Loader/Loader";
import "./SingleClipping.css";

const SingleClipping = () => {
  const { fetchedAllData } = useContext(appCtx);
  const { clippingId } = useParams();
  const [singleClipping, setsingleClipping] = useState(null);

  useEffect(() => {
    let singleClippingData = null;
    for (let i = 0; i < fetchedAllData?.pressClippingData.length; i++) {
      if (fetchedAllData.pressClippingData[i]._id == clippingId) {
        singleClippingData = fetchedAllData.pressClippingData[i];
        i = fetchedAllData.pressClippingData.length;
      }
    }
    setsingleClipping(singleClippingData);
  }, [fetchedAllData, clippingId]);
  return (
    <>
      {singleClipping && (
        <>
          <main id="single-clipping-container">
            <div className="header-footer-shadow"></div>
            <h1>{singleClipping.naslov}</h1>
            <p className="kratki-opis">
              <i>{singleClipping.kratkiOpis}</i>
            </p>
            <img src={singleClipping.slika} alt="slika" />
            <div
              className="rich-text-wrapper"
              dangerouslySetInnerHTML={{ __html: singleClipping.richTekst }}
            />
          </main>
          <div className="header-footer-shadow"></div>
        </>
      )}
      {!singleClipping && <Loader />}
    </>
  );
};

export default SingleClipping;
