import React from "react";
import knowledgeBulb from "../../assets/knowledge-bulb.svg";
import checklist from "../../assets/checklist.svg";
import './Onama.css';

const Onama = () => {

    return (
        <main id="o-nama-container">
            <div className="header-footer-shadow"></div>
            <section className="section-1-container">
                <h1>Ko smo mi?</h1>
                <p>Sada već davne, 2015. godine, okupili smo se oko ideje da obogatimo društveni život i mladima u
                    Sremu ponudimo više kulturnog, umetničkog i edukativnog sadržaja. Tako je nastao Omladinsko
                    turistički klaster Srem, nevladino i neprofitno udruženje, čiji je opšti cilj da podstiče i unapređuje
                    položaj mladih, afirmiše njihovo aktivno učešće u razvoju zajednice i radi na edukaciji dece i
                    omladine.</p>
                    <br />
                <p>Nakon niza realizovanih projekata, od lokalne inicijative, postali smo organizacija koja deluje na
                    teritoriji cele zemlje. U partnerstvu sa spoljnim saradnicima, naš tim danas čini široku mrežu
                    obrazovanih, uspešnih i entuzijastičnih mladih ljudi koji su motivisani da rade na unapređenju
                    položaja svojih vršnjaka.</p>
                    <br />
                <p>Naši najznačajniji projekti odnose se na neformalnu edukaciju i podizanje kompetencija mladih za
                    zapošljivost, što ostvarujemo realizacijom radionica o preduzetništvu, digitalnom marketingu i
                    programiranju.</p>
                    <br />
                <p>Tokom 2021. godine, održali smo šest radionica „Uvod u IT i WordPress“ i 6 radionica „Uvod u
                    preduzetništvo“ u gradovima širom Srbije. Tom prilikom, posetili smo Čačak, Suboticu, Bor, Vršac,
                    Pirot i Inđiju. Taj projekat, pod nazivom „Informatička pismenost i preduzetništvo – nove veštine
                    za bolju zapošljivost mladih“ realizovali smo u periodu od juna do decembra 2021. godine, a
                    projekat je finansiran od strane Ministarstva omladine i sporta Republike Srbije.</p>
                    <br />
                <p>I ove godine nastavljamo saradnju sa Ministarstvom kroz realizaciju projekta „Informatička
                    pismenost i preduzetništvo – moderni alati za razoj (samo)zapošljvosti mladih“. Do sada smo bili u
                    Aranđelovcu i Kraljevu, a u narednom periodu planiramo da posetimo Vranje, Valjevo, Užice i
                    druge gradove u Srbiji.</p>
                    <br />
                <p>Pratite naš sajt i društvene mreže i budite u toku sa svim našim aktivnostima!</p>
            </section>
            <section className="section-2-container">
                <img src={knowledgeBulb} alt="knowledge-bulb" />
                <div className="txt-wrapper">
                    <h2>Misija</h2>
                    <p>Pomažemo mladima da ostvare svoj pun potencijal. Kreiramo povoljno okruženje koje mlade
                        podstiče da otkriju svoje talente, usavrše ih i iskažu na najbolji mogući način.</p>
                </div>
            </section>

            <section className="section-3-container">
                <img src={checklist} alt="knowledge-bulb" />
                <div className="txt-wrapper">
                    <h2>Vizija</h2>
                    <p>Želimo da doprinesemo stvaranju aktivnog i dinamičnog omladinskog sektora u našoj zemlji.</p>
                </div>
            </section>
            <div className="header-footer-shadow"></div>

        </main>
    );
}

export default Onama;
