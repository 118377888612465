import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { appCtx } from "../../context";
import Loader from "../Loader/Loader";

import "./Projekti.css";

const Projekti = () => {
  const { fetchedAllData } = useContext(appCtx);
  const mapProjekti = () => {
    return fetchedAllData.projektiData.map((singleProjekat) => {
      return (
        <div className="vest-card-container" key={singleProjekat._id}>
          <div className="vest-img-wrapper">
            <img src={singleProjekat.slika} alt="slika" />
          </div>
          <div className="vest-not-img-wrapper">
            <div>
              <h2>{singleProjekat.naslov}</h2>
            </div>
            <p>{singleProjekat.kratkiOpis}</p>
            <Link to={`/projekat/${singleProjekat._id}`}>
              <p className="btn">Saznaj više</p>
            </Link>
          </div>
        </div>
      );
    });
  };

  return (
    <main id="projekti-container">
      <div className="header-footer-shadow"></div>
      <div className="projekti-inner-wrapper">
        <h1>Projekti</h1>
        {fetchedAllData ? mapProjekti() : <Loader />}
      </div>

      <div className="header-footer-shadow"></div>
    </main>
  );
};

export default Projekti;
