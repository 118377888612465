import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { appCtx } from "../../context";
import Loader from "../Loader/Loader";
import "./SingleVest.css";

const SingleVest = () => {
  const { fetchedAllData } = useContext(appCtx);
  const { vestId } = useParams();
  const [singleVest, setSingleVest] = useState(null);

  useEffect(() => {
    let singleVestData = null;
    for (let i = 0; i < fetchedAllData?.vestiData.length; i++) {
      if (fetchedAllData.vestiData[i]._id == vestId) {
        singleVestData = fetchedAllData.vestiData[i];
        i = fetchedAllData.vestiData.length;
      }
    }
    setSingleVest(singleVestData);
  }, [fetchedAllData, vestId]);

  return (
    <>
      {singleVest && (
        <>
          <main id="single-vest-container">
            <div className="header-footer-shadow"></div>
            <h1>{singleVest.naslov}</h1>
            <p className="kratki-opis">
              <i>{singleVest.kratkiOpis}</i>
            </p>
            <img src={singleVest.slika} alt="slika" />
            <p className="datum-objave">Objavljeno: {singleVest.datum}</p>
            <div
              className="rich-text-wrapper"
              dangerouslySetInnerHTML={{ __html: singleVest.richTekst }}
            />
          </main>
          <div className="header-footer-shadow"></div>
        </>
      )}
      {!singleVest && <Loader />}
    </>
  );
};

export default SingleVest;
