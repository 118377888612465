import React from "react";
import { Link } from "react-router-dom";
import crewImg from "../../assets/crew.svg";
import lecturingImg from "../../assets/lecturing.svg"
import './Home.css';

const Home = () => {
    return (
        <main id="home-container">
            <section className="section-1-container">
                <img src={crewImg} alt="crew" />
                <div className="section-1-txt-wrapper">
                    <h1></h1>
                    <p>Sada već davne, 2015. godine, okupili smo se oko ideje da obogatimo društveni život i mladima u
                        Sremu ponudimo više kulturnog, umetničkog i edukativnog sadržaja. Tako je nastao Omladinsko
                        turistički klaster Srem, nevladino i neprofitno udruženje, čiji je opšti cilj da podstiče i unapređuje
                        položaj mladih, afirmiše njihovo aktivno učešće u razvoju zajednice i radi na edukaciji dece i
                        omladine.</p>
                </div>
            </section>

            <section className="section-2-container">
                <img src={lecturingImg} alt="lecturing" />
                <div className="announcing-container">
                    <div className="card-wrapper">
                        <h2>Lorem Ipsum</h2>
                        <p>Ni bestoj la al enprenus unua enboatigxis.</p>
                        <Link to={"/"}>
                            <p className="btn">
                                Saznaj više
                            </p>
                        </Link>
                    </div>

                    <div className="card-wrapper">
                        <h2>Lorem Ipsum</h2>
                        <p>Ni bestoj la al enprenus unua enboatigxis.</p>
                        <Link to={"/"}>
                            <p className="btn">
                                Saznaj više
                            </p>
                        </Link>
                    </div>
                </div>
            </section>
        </main>
    );
}

export default Home;
