import React from "react";
import facebookIcon from "../../assets/facebook-icon.png";
import instagramIcon from "../../assets/instagram-icon.png";
import littleHouseIcon from "../../assets/kontakt-kucuca.png";
import emailIcon from "../../assets/kontakt-email.svg";
import './Kontakt.css';

const Kontakt = () => {

    return (
        <main id="kontakt-container">
            <div className="header-footer-shadow"></div>
            <div className="kontakt-inner-wrapper">
                <h1>Kontakt</h1>

                <div className="items-container">

                    <div className="item-wrapper">
                        <h2>OMLADINSKO TURISTIČKI <br />
                            KLASTER SREM</h2>
                        <div className="icons-wrapper">
                            <a className="facebook-icon" href="https://www.facebook.com/omladinskiklaster" target="_blank" rel="noopener noreferrer">
                                <img src={facebookIcon} alt="facebook" />
                            </a>
                            <a href="https://www.instagram.com/explore/locations/105545418317294/omladinsko-turisticki-klaster-srem/" target="_blank" rel="noopener noreferrer">
                                <img src={instagramIcon} alt="instagram" />
                            </a>
                        </div>
                    </div>
                    <a href="https://www.google.com/maps/place/Fru%C5%A1kogorska+11,+Irig,+Serbia/@45.1015756,19.8613511,17z/data=!3m1!4b1!4m5!3m4!1s0x475b072f141abb49:0xa65c19d6f6723355!8m2!3d45.1015756!4d19.8613511" className="item-wrapper" target="_blank" rel="noopener noreferrer">
                        <h2><img src={littleHouseIcon} alt="icon" /> KANCELARIJA</h2>
                        <p>Fruškogorska 11 <br />
                            Irig, Srem</p>
                    </a>

                    <a href="mailto:office@omladinskiklaster.rs" className="item-wrapper">
                        <h2><img src={emailIcon} alt="icon" /> E MAIL</h2>
                        <p>office@omladinskiklaster.rs</p>
                    </a>
                </div>
            </div>

            <div className="header-footer-shadow"></div>

        </main>
    );
}

export default Kontakt;
