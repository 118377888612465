import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { appCtx } from "../../context";
import Loader from "../Loader/Loader";

import "./PressClipping.css";

const PressClipping = () => {
  const { fetchedAllData } = useContext(appCtx);

  console.log(fetchedAllData);
  const mapPressClipping = () => {
    return fetchedAllData.pressClippingData.map((singleClipping) => {
      return (
        <a href={singleClipping.linkURL} target="_blank" rel="noreferrer">
          <div className="card-wrapper">
            <img src={singleClipping.slika} alt="" />
            <p>{singleClipping.naslov}</p>
          </div>
        </a>
      );
    });
  };

  return (
    <main id="press-clipping-container">
      <div className="header-footer-shadow"></div>
      <div className="press-clipping-inner-wrapper">
        <h1>Press Clipping</h1>
        <div className="cards-container">
          {fetchedAllData && mapPressClipping()}
        </div>
        {!fetchedAllData && <Loader />}
      </div>
      <div className="header-footer-shadow"></div>
    </main>
  );
};

export default PressClipping;
